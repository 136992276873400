body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Murecho", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  background-color: #F6FBFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
::-webkit-scrollbar {
  width: 3px;
  color: #003478;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}

html {
  scrollbar-width: 1px;
  scrollbar-color: black;
}
 */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cri-gradient {
  background: linear-gradient(to top right, #2c3f59 20%, #1857AB 70%, #467bbf 100%);
}


/* gradient template */

.cri-gradient-bl-to-tr {
  background: linear-gradient(to top right, rgba(212, 212, 212, 0.05), rgba(255, 255, 255, 0.1))
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 0;
}

.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll::-webkit-scrollbar {
  width: 16px;
}

.scroll::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 8px;
  background-color: transparent;
  background-clip: padding-box;
}

.scroll:hover::-webkit-scrollbar-thumb {
  background-color: #003478;
}

.fade-enter {
  opacity: 0;
  transform: translateY(0);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 0;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 1;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.carousel {
  scroll-behavior: smooth;
}

.animate-marquee {
  display: flex;
  animation: marquee 1s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  /* White background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it stays on top */
}

.logo-container {
  margin-bottom: 20px;
}

.loading-logo {
  width: 100px;
  height: auto;
  animation: pulse 10s normal;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: scale(2)
  }
}

.ql-editor {
  padding: 0;
}

.ql-editor .ql-align-center {
  text-align: right;
}

img {
  display: block;
  width: 800px;
}

p.ql-align-center img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p.ql-align-right img {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

p.ql-align-left img {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.ql-editor iframe {
  width: 60% !important;
  min-height: 400px !important;
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

/* .ql-editor ol {
  padding-left: 0;
} */

.ql-editor p {
  padding-left: 0em !important;
  font-weight: 400;


}

.ql-editor ol {
  padding-left: 0em !important;
  padding-top: 10px !important;
  font-size: 0.88rem;
  font-weight: 400;
  /* padding: 0 !important; */
}

.ql-editor li {
  list-style-type: disc;
  padding-left: 1em !important;
  /* margin-bottom: 5px; */
  margin: 0 !important;
}

.ql-editor h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #162e4e;
}

.ql-editor h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #162e4e;

}

.ql-editor h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #162e4e;

}


.ql-editor a:hover {
  color: #1857AB;

}

/* Styling untuk Inline Code */
.ql-editor code {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
  color: #d63384;
}

/* Styling untuk Code Block */
.ql-code-block-container {
  background-color: #282c34;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
  overflow-x: auto;
}

.ql-code-block {
  background: none !important;
  border: none !important;
  padding: 0;
}

.card {
  scroll-padding-left: 50vw;
}